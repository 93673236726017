<template>
  <svg viewBox="6 6 20 20">
    <path
      d="M 16.0003 29.3334 C 8.6363 29.3334 2.667 23.364 2.667 16 C 2.667 8.636 8.6363 2.6667 16.0003 2.6667 C 23.3643 2.6667 29.3337 8.636 29.3337 16 C 29.3337 23.364 23.3643 29.3334 16.0003 29.3334 Z M 14.671 21.3334 L 24.0977 11.9054 L 22.2123 10.02 L 14.671 17.5627 L 10.899 13.7907 L 9.0137 15.676 L 14.671 21.3334 Z L 4 16 m 12 -12 C 10 4 4 8 4 16 C 4 23 9 28 16 28 C 22 28 28 23 28 16 C 28 11 23 4 16 4"
    />
  </svg>
</template>

<script>
export default {
  name: "CheckIconNoBg",
};
</script>
